import React from 'react';
import { Link } from 'react-router-dom';

import "./styles.scss";
import {MENU} from "../../utils/menu";

type BreadcrumbsType = {
  category: string;
  productName: string;
}

const Breadcrumbs = ({ category, productName }: BreadcrumbsType) => {
  const findCategory = (categoryTitle: string) => {
    return MENU.find((v) => v.categoryTitle === categoryTitle)?.category ?? "";
  };

  return (
    <div className="breadcrumbs">
      <Link to="/menu">Меню</Link>
      {category && (
        <>
          <span> / </span>
          <Link to={`/menu/${findCategory(category)}`}>{category}</Link>
        </>
      )}
      {productName && (
        <>
          <span> / </span>
          <span>{productName}</span>
        </>
      )}
    </div>
  );
};

export default Breadcrumbs;
