type ProductType = {
  name: string;
  price: number;
  description?: string;
  imgName?: string | string[];
  storageConditions?: string;
  ingredients?: string;
  isDisabled?: boolean;
  id: number;
};

type MenuItemType = {
  category: string;
  categoryTitle: string;
  storageConditions?: string;
  isDisabled?: boolean;
  products: ProductType[]
};

export const MENU = [
  {
    category: "Croissant",
    categoryTitle: "Круассаны",
    storageConditions: "Круассаны готовы ждать встречи с вами хоть всю свою жизнь, но в лучшей форме они находятся на протяжении 16 часов после покупки в сухом и прохладном месте.",
    products: [{
      name: 'Круассан миндальный',
      price: 295,
      description: "Насладитесь нашим миндальным круассаном: хрустящее слоёное тесто, щедро посыпанное миндальными лепестками, скрывает внутри нежный крем. Этот десерт станет вашим любимым угощением!",
      imgName: ['croissant-almond.jpg', 'croissant-almond-2.jpg', 'croissant-almond-3.jpg'],
      id: 1001,
    },
    //   {
    //   name: 'Плоский круассан c клубникой',
    //   price: 290,
    //   description: "Впервые в городе — плоские круассаны в премиальном белом шоколаде с кусочками клубники. Станьте одним из первых, кто оценит это угощение, созданное исключительно для гурманов.",
    //   imgName: 'croissant-flat.jpg',
    //   id: 1002,
    // },
    {
      name: 'Круассан соленая карамель',
      price: 245,
      description: "Откройте для себя наш круассан с солёной карамелью: хрустящее слоёное тесто, тающее во рту, и изысканная солёная карамель внутри. Идеальное сочетание сладости и солёности в каждом кусочке!",
      imgName: 'croissant-caramel.jpg',
      id: 1003,
    }, {
      name: 'Круассан с нутеллой',
      price: 295,
      description: "Погрузитесь в шоколадное наслаждение с нашим круассаном с нутеллой: хрустящее слоёное тесто и нежная нутелла внутри создают идеальное сочетание для истинных гурманов.",
      imgName: 'croissant-nutella.jpg',
      id: 1005,
    }, {
      name: 'Круассан с малиной',
      price: 255,
      id: 1009,
      description: "Погрузитесь в удовольствие с нашим круассаном с малиной: слоёное тесто и нежное конфи из натуральной малины внутри создают восхитительное сочетание.",
      imgName: 'croissant-razz.jpg'
    },
    { name: 'Круассан с красной рыбой', price: 445, id: 1007, imgName: ["croissant-salmon.jpg", "croissant-salmon-2.jpg"] },
    { name: 'Круассан с ветчиной и сыром', price: 375, id: 1008 },
    { name: 'Круассан сосиска-горчица', price: 255, id: 1010 },
    { name: 'Круассан ветчина-грибы', price: 225, id: 1011 },
    { name: 'Круассан курица-карри', price: 225, id: 1012 },
    { name: 'Круассан клубника со сливками', price: 255, id: 1013 },
    ]
  },
  {
    category: "Bread",
    categoryTitle: "Хлеб",
    storageConditions: "Хранить в хлебнице при комнатной температуре. Избегать влажности и прямых солнечных лучей. Хлеб рекомендуется употребить в течение 2-3 дней.",
    products: [{
      name: 'Тартин',
      price: 150,
      description: "Откройте для себя тартин - хрустящий снаружи и нежный внутри. Идеальный выбор для любителей традиционного хлеба с современным шиком.",
      id: 1101,
      imgName: ['tartin-2.jpg', 'tartin.jpg', 'tartin-3.jpg', 'tartin-4.jpg'],
    }, {
      name: 'Багет',
      price: 100,
      description: "Наш багет — это хрустящая корочка и воздушный мякиш, испеченный из отборной муки и натуральных ингредиентов, каждый ломтик которого несет в себе настоящий вкус Франции.",
      imgName: ['baguette.jpg', 'baguette-2.jpg', 'baguette-3.jpg'],
      id: 1102,
    },
    {
      name: "Тартин с томатами", price: 250, id: 1106,
      storageConditions: "Хранить в хлебнице при комнатной температуре. Избегать влажности и прямых солнечных лучей. Хлеб рекомендуется употребить в течение 2-3 дней. Иногда томаты могут отдавать влагу в мякиш, это не является браком.",
      description: "Откройте для себя наш тартин с томатом на закваске: хрустящая основа, наполненная ароматом свежих томатов, дарит незабываемое наслаждение каждому кусочку!",
      imgName: ['tartin-tomat.jpg', 'tartin-tomat-2.jpg', 'tartin-tomat-3.jpg', 'tartin-tomat-4.jpg'],
    },
      {
        name: "Тартин с оливками", price: 250, id: 1106,
        storageConditions: "Хранить в хлебнице при комнатной температуре. Избегать влажности и прямых солнечных лучей. Хлеб рекомендуется употребить в течение 2-3 дней. Иногда томаты могут отдавать влагу в мякиш, это не является браком.",
        imgName: ['tartin-olives.jpg', 'tartin-olives-2.jpg', 'tartin-olives-3.jpg', 'tartin-olives-4.jpg'],
      },
    {
      name: "Чиабатта",
      price: 100,
      id: 1108,
      description: "Ощутите магию итальянских вкусов с нашей чиабаттой: воздушная и хрустящая корочка, наполненная мягкой, ароматной мякотью. Каждый кусочек – праздник для вашего стола!",
      imgName: ['chiabatta.jpg', 'chiabatta-2.jpg', 'chiabatta-3.jpg']
    },
    {
      name: "Тартин халапеньо-бекон", price: 250, id: 1107,
      storageConditions: "Хранить в хлебнице при комнатной температуре. Избегать влажности и прямых солнечных лучей. Хлеб рекомендуется употребить в течение 2-3 дней. Иногда бекон может отдавать влагу в мякиш, это не является браком.",
    },
    {
      name: "Бородинский", price: 200, id: 1103,
      storageConditions: "Хранить в хлебнице при комнатной температуре. Избегать влажности и прямых солнечных лучей. Хлеб рекомендуется употребить в течение 2-3 дней. Изредка, мякиш в центре может быть очень влажным, это не является браком.",
      imgName: ['borodino.jpg', 'borodino-2.jpg']
    },
    { name: "Гулинский", price: 220, id: 1104, imgName: ['gulinsky.jpg', 'gulinsky-2.jpg']},
    { name: "Мультизлаковый", price: 200, id: 1105, imgName: ['multizlak.jpg', 'multizlak-2.jpg']}]
  },
  {
    category: "Pastries",
    categoryTitle: "Выпечка",
    storageConditions: "Хранить в герметичной упаковке в прохладном месте. Можно хранить в холодильнике Срок хранения - до 5 дней.",
    products: [
      {
        name: "Симит",
        price: 100,
        id: 1207,
        description: "Погрузитесь в турецкую сказку с нашим симитом: хрустящее золотистое кольцо с щедрой посыпкой кунжута. Попробуйте и ощутите настоящий восточный колорит!",
        imgName: ['simit.jpg', 'simit-2.jpg'],
      },
      {
        name: "Маковая булочка",
        description: "Наша маковая булочка — это истинное удовольствие для гурмана. Щедро наполненная маком высшего сорта, каждая булочка выпекается до золотистой корочки и идеально подходит для утреннего кофе.",
        imgName: ["mak-2.jpg", "mak.jpg"],
        price: 150,
        id: 1205
      },
      { name: "Канле", price: 120, id: 1201, imgName: "kanle.jpg" },
      { name: "Шведская булочка", price: 130, id: 1213, imgName: "sweden-pastry.jpg" },
      { name: "Рогалик со сгущенкой", price: 150, isDisabled: true, id: 1206, imgName: ["rogalik.jpg", "rogalik-2.jpg"] },
      { name: "Слойка с черри", price: 170, id: 1210, imgName: "sloyka-cherry.jpg" },
      { name: "Кантуччи", price: 300, id: 1202 },
      { name: "Киш", price: 250, isDisabled: true, id: 1203 },
      { name: "Мадлен", price: 300, id: 1204 },
      { name: "Таралли с трюфелем", price: 300, id: 1211 },
      { name: "Флейта с солью", price: 100, id: 1212 },
    ]
  },
  {
    category: "Cakes",
    categoryTitle: "Торты",
    storageConditions: "Хранить в холодильнике при температуре 4±2°C. Торт рекомендуется употребить в течение 3-4 дней.",
    products: [
      {
        name: "Киндер",
        price: 250,
        id: 1505,
        imgName: "kinder.jpg",
        description: "Шоколадный торт \"Киндер\" - это сочный бисквит, прослоенный молочным кремом и украшенный премиальным шоколадом. Каждый кусочек - праздник вкуса с нотками детства.",
      },
      {
        name: "Медовик с клюквой",
        description: "Наш медовик - это слои изысканного бисквита и нежного крема, пропитанного натуральным медом, что делает его истинным наслаждением для сладкоежек.",
        imgName: "medovik.jpg",
        price: 250,
        id: 1510
      },
      { name: "Баунти", price: 279, id: 1501, imgName: "bounty.jpg", },
      { name: "Смородина-шоколад", price: 329, id: 1513, imgName: "currant-chocolate.jpg" },
      { name: "Киви-клубника", price: 249, id: 1504, imgName: "kiwi-strawberry.jpg" },
      { name: "Манго-маракуйя", price: 360, id: 1507, imgName: "mango-marakuya.jpg" },
      { name: "Сникерс", price: 349, id: 1514, imgName: "snikers.jpg" },
      { name: "Йогурт-ягоды", price: 380, id: 1503 },
      { name: "Красный бархат", price: 249, id: 1506 },
      { name: "Мак-манго-облепиха", price: 249, id: 1508 },
      { name: "Медовик шоколад-апельсин", price: 450, id: 1511 },
      { name: "Наполеон", price: 250, id: 1512 },
      { name: "Три шоколада", price: 299, id: 1515 },
      { name: "Флан", price: 280, id: 1516 },
      // { name: "Чизкейк смородина", price: 290, id: 1517 },
      { name: "Капучино-байлис", price: 349, id: 1518 },
      { name: "Миндаль-кокос", price: 319, id: 1519 },
      { name: "Морковный торт", price: 319, id: 1520 },
      // { name: "Пирог смородина", price: 290, id: 1521 },
      { name: "Птичье молоко", price: 279, id: 1522 },
    ]
  },
  {
    category: "Cupcakes",
    categoryTitle: "Кексы",
    storageConditions: "Хранить в плотно закрытой упаковке при комнатной температуре. Избегать мест с высокой влажностью. Кексы можно хранить до 7 дней.",
    products: [
      { name: "Морковный кекс", price: 150, id: 1304, imgName: ["carrot-cake.jpg", "carrot-cake-2.jpg"] }
    ]
  },
  {
    category: "Desserts",
    categoryTitle: "Десерты",
    storageConditions: "Хранить в холодильнике при температуре 4±2°C. Срок хранения от 3 до 7 дней.",
    products: [
      { name: "Картошка", price: 200, id: 1401 },
      { name: "Крем-брюле", price: 250, isDisabled: true, id: 1402 },
      { name: "Маритоццо капучино", price: 200, id: 1403 },
      { name: "Павлова", price: 400, id: 1404 },
      { name: "Синнабон", price: 170, isDisabled: true, id: 1406 },
      { name: "Тарталетка", price: 360, isDisabled: true, id: 1408 },
      { name: "Тирамису", price: 400, id: 1409 },
      { name: "Трайфл", price: 250, id: 1410 }
    ]
  },
  {
    category: "Truffles",
    categoryTitle: "Трюфели",
    storageConditions: "Хранить в прохладном месте при температуре от 12 до 18°C. Избегать влажности. Трюфели можно хранить до 2 недель.",
    products: [
      { name: "Трюфель с куантро", price: 120, id: 1601 },
      { name: "Трюфель с ягермейстером", price: 120, id: 1602 },
      { name: "Сырный трюфель", price: 120, id: 1603 },
    ]
  },
  {
    category: "Eclairs",
    categoryTitle: "Эклеры",
    storageConditions: "Хранить в холодильнике при температуре 4±2°C. Употребить в течение 24 часов для сохранения наилучших вкусовых качеств.",
    products: [
      { name: "Эклер ванильный", price: 230, id: 1701 },
      { name: "Эклер клубничный", price: 230, id: 1702 },
      { name: "Эклер шоколадный", price: 230, id: 1703 },
      { name: "Эклер фисташковый", price: 230, id: 1703 }
    ]
  },
  {
    category: "Rolls",
    categoryTitle: "Рулеты",
    storageConditions: "Хранить в холодильнике. Рекомендуется употребить в течение 2-3 дней после покупки для сохранения свежести.",
    products: [
      { name: "Меренговый рулет", price: 200, id: 1801 },
    ]
  },
  {
    category: "Matcha",
    categoryTitle: "Матча",
    products: [{
      name: 'Матча классическая',
      price: [{size: 'M', price: 250}, {size: 'L', price: 290}],
      id: 1903,
    }, {
      name: 'Розовая малина-питахайя',
      price: [{size: 'M', price: 260}, {size: 'L', price: 300}],
      description: "Розовая матча в элегантном бокале - не просто напиток, но и украшение стола, предлагающее изысканный вкус и эстетическое удовольствие.",
      imgName: 'matcha.jpg',
      id: 1901,
    }, {
      name: 'Соленая фисташка',
      price: [{size: 'M', price: 260}, {size: 'L', price: 300}],
      id: 1902,
    }]
  },
  {
    category: "Coffee-classic",
    categoryTitle: "Классический Кофе",
    products: [{
      name: 'Эспрессо',
      price: [{size: '1x', price: 90}, {size: '2x', price: 130}],
      description: "Классическая порция вкуснейшего эспрессо",
      id: 2001,
    }, {
      name: 'Американо',
      price: [{size: 'M', price: 120}, {size: 'L', price: 150}],
      id: 2002,
    }, {
      name: 'Капуччино',
      price: [{size: 'M', price: 190}, {size: 'L', price: 230}],
      description: "Классическая порция вкуснейшего капуччино",
      id: 2003,
    }, {
      name: 'Латте',
      price: [{size: 'M', price: 200}, {size: 'L', price: 240}],
      description: "Классическая порция вкуснейшего латте",
      id: 2004,
    }, {
      name: 'Раф',
      price: [{size: 'M', price: 240}, {size: 'L', price: 280}],
      description: "Классическая порция вкуснейшего рафа",
      id: 2005,
    }, {
      name: 'Флэт Уайт',
      price: 210,
      description: "",
      id: 2006,
    }]
  },
  {
    category: "Coffee-author",
    categoryTitle: "Авторский Кофе",
    products: [{
      name: 'Клубника-банан',
      price: [{size: 'M', price: 270}, {size: 'L', price: 310}],
      description: "Попробуйте популярное и всеми любимое сочетание клубника-банан",
      id: 2101,
    }, {
      name: 'Малина-кокос',
      price: [{size: 'M', price: 270}, {size: 'L', price: 310}],
      description: "Попробуйте изумительное сочетание малины и кокоса ",
      id: 2102,
    }, {
      name: 'Белый шоколад с базиликом',
      price: [{size: 'M', price: 270}, {size: 'L', price: 310}],
      description: "Попробуйте Изысканный белый шоколад с базиликом",
      id: 2103,
    },{
      name: 'Барбарис-мята',
      price: [{size: 'M', price: 270}, {size: 'L', price: 310}],
      description: "",
      id: 2104,
    },{
      name: "Персик",
      price: [{size: 'M', price: 270}, {size: 'L', price: 310}],
      description: "",
      id: 2105,
    },{
      name: "Сливочная лаванда",
      price: [{size: 'M', price: 270}, {size: 'L', price: 310}],
      description: "",
      id: 2106,
    }, {
      name: "Сырный",
      price: [{size: 'M', price: 270}, {size: 'L', price: 310}],
      description: "",
      id: 2107,
    },{
      name: 'Миндальное печенье',
      price: [{size: 'M', price: 270}, {size: 'L', price: 310}],
      description: "Погрузитесь в сладость миндальное печенье",
      id: 2108,
    }]
  },
  {
    category: "Cocoa_tea",
    categoryTitle: "Какао/чай",
    products: [{
      name: 'Какао Апельсиновый мармелад',
      price: [{size: 'M', price: 230}, {size: 'L', price: 280}],
      id: 2202,
    }, {
      name: 'Чай',
      price: [{size: 'S', price: 150}, {size: 'M', price: 250}, {size: 'L', price: 350}],
      id: 2301,
    }]
  },
] as MenuItemType[]
