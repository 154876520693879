import React from 'react';
import './styles.scss';
import {tel} from "../../../../utils/constants";

export type KeyProductProps = {
  title: string;
  description: string;
  price: string;
  buttonText: string;
  image: string;
}

const KeyProduct: React.FC<KeyProductProps> = ({ title, description, price, buttonText, image }) => {
  const handleCall = () => {
    window.open(`tel:${tel}`, "_blank");
  };

  return (
    <div className="key-product">
      <div className="key-product__content">
        <h1 className="key-product__title">{title}</h1>
        <p className="key-product__description">{description}</p>
        <div className="key-product__footer">
          <span className="key-product__footer__price">{price}</span>
          <button className="key-product__footer__buy-button" onClick={handleCall}>{buttonText}</button>
        </div>
      </div>
      <img className="key-product__image" src={`/images/${image}`} />
    </div>
  );
};

export default KeyProduct;
