import React from 'react';

import './styles.scss';
import KeyProducts from "./supportComponents/KeyProducts";
import ValuesSection from "./supportComponents/ValuesSection";
import Welcome from "./supportComponents/Welcome";

function Main() {
  return (
    <div className="main">
      <Welcome />

      <KeyProducts />
      <ValuesSection />
    </div>
  );
}

export default Main;
