import React, {useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { MENU } from '../../utils/menu';
import './styles.scss';
import Breadcrumbs from "../../components/Breadcrumbs";
import {Tab, Tabs} from "../../components/Tabs";
import {tel} from "../../utils/constants";
import {useDevice} from "../../hooks/useDimensions";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Scrollbar, A11y} from "swiper/modules";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

function ProductDetail() {
  const { productId } = useParams();
  const { isMobile } = useDevice();
  const product = MENU.flatMap(category => category.products).find(p => p.id.toString() === productId);

  useEffect(() => {
    window.scrollTo(0, 0);  // Прокрутка вверх при каждом монтировании компонента
  }, []);

  if (!product) {
    return <h2>Продукт не найден</h2>;
  }

  const category = MENU.find(category => category.products.some(p => p.id.toString() === productId));

  const storageConditions = product.storageConditions ?? category?.storageConditions;

  const handleClick = () => {
    window.open(`https://wa.me/${tel}`, "_blank");
  }

  const renderImages = () => {
    if (Array.isArray(product.imgName)) {
      return (
        <Swiper
          modules={[Navigation, Scrollbar, A11y]}
          spaceBetween={10}
          slidesPerView={1}
          navigation
          scrollbar={{ draggable: true }}
          loop={true}
        >
          {product.imgName.map((img, index) => (
            <SwiperSlide key={index}>
              <img src={`/images/menu/${img}`} alt={`${product.name} ${index + 1}`} width={100} />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }
    return <img src={product.imgName ? `/images/menu/${product.imgName}` : '/images/no-image.jpg'} alt={product.name}/>;
  };

  return (
    <div className="product-detail">
      {category ? <Breadcrumbs category={category.categoryTitle || category.category} productName={product.name} /> : null}

      <div className="product-content">
        <div className="product-images">
          {renderImages()}
        </div>
        <div className="product-info">
          <div className="product-info__title">{product.name}</div>
          {!isMobile && <div className="product-info__category">{category?.categoryTitle}</div>}
          <div className="price">{Array.isArray(product.price) ? product.price.map(p => `${p.size}: ${p.price}`).join(', ') : product.price} ₽</div>
          <div className="product-info__description-title">Описание</div>
          <div className="product-info__description">{product.description}</div>
          <button className="product-info__button button-default primary" onClick={handleClick}>Забронировать</button>
          <Tabs>
            {product.ingredients ? (
              <Tab label="Состав">
                <p>{product.description}</p>
              </Tab>
            ) : null}
            {storageConditions ? (
              <Tab label="Хранение">
                <p>{storageConditions}</p>
              </Tab>
            ) : null}
            <Tab label="Доставка">
              <p>
                Доставка осуществляется через Яндекс.Доставку. Стоимость зависит от суммы вашего заказа и адреса
                доставки.
                <br/>
                Для уточнения напишите или позвоните нам нажав на кнопку выше.
              </p>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
