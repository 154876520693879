import React from "react";
import { Routes, Route } from "react-router-dom";

import PageNotFound from "../../pages/PageNotFound";
import Main from "../../pages/Main";
import Contacts from "../../pages/Contacts";
import Menu from "../../pages/Menu";
import ProductDetail from "../../pages/ProductDetail";

const MainRoutes = () => (
  <Routes>
    <Route path="/" element={<Main />} />
    <Route path="/contacts" element={<Contacts />} />
    <Route path="/menu" element={<Menu />} />
    <Route path="/menu/:category" element={<Menu />} />
    <Route path="/menu/product/:productId" element={<ProductDetail />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default MainRoutes;
