import React from 'react';

import {Header} from "../Header";
import {Footer} from "../Footer";
import MainRoutes from "../MainRoutes";

import './styles.scss';

function App() {
  return (
    <div className="App">
      <Header />

      <div className="App-container">
        <MainRoutes />
      </div>
      <Footer />
    </div>
  );
}

export default App;
