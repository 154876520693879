import React from 'react';

import './styles.scss';

function PageNotFound() {
  return (
    <div className="no-found">
      Страница не найдена
    </div>
  );
}

export default PageNotFound;
