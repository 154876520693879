import React, { useState } from 'react';
import "./styles.scss";

type TabProps = {
  label: string;
  children: React.ReactElement;
}
export function Tab({ children }: TabProps) {
  return <div>{children}</div>;
}

type TabsProps = {
  children: (React.ReactElement | null)[];
}
export function Tabs({ children }: TabsProps) {
  const [activeTab, setActiveTab] = useState(children.filter(v => v)[0]?.props.label);

  return (
    <div>
      <div className="tabs">
        {children.filter(v => v).map((tab) => {
          const label = tab?.props.label;
          return (
            <button key={label} className={activeTab === label ? 'tab-button active' : 'tab-button'} onClick={() => setActiveTab(label)}>
              {label}
            </button>
          );
        })}
      </div>
      <div className="tab-content">
        {children.filter(v => v).map((tab) => {
          if (tab?.props.label !== activeTab) return undefined;
          return tab?.props.children;
        })}
      </div>
    </div>
  );
}