import React from "react";

import { SlSocialVkontakte } from "react-icons/sl";
import { SiWhatsapp } from "react-icons/si";
import { BsTelephone } from "react-icons/bs";
import { IoLogoInstagram } from "react-icons/io5";

import "./styles.scss";
import {tel} from "../../utils/constants";

export const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__social">
              {/*<a href="https://vk.com" target="_blank" rel="noreferrer">*/}
              {/*  <SlSocialVkontakte color="#171212" size={28} />*/}
              {/*</a>*/}
              <a href={`https://wa.me/${tel}`} target="_blank" rel="noreferrer">
                <SiWhatsapp color="#171212" size={20} />
              </a>
              <a href={`tel://${tel}`} target="_blank" rel="noreferrer">
                <BsTelephone color="#171212" size={20} />
              </a>
              <a href="https://www.instagram.com/la_rouletterie/" target="_blank" rel="noreferrer">
                <IoLogoInstagram color="#171212" size={23} />
              </a>
            </div>
        </div>
    );
};
