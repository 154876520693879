import React from "react";
import cn from "classnames";
import { useNavigate } from 'react-router-dom';

import "./styles.scss";

const LINKS = [{ title: "Главная", link: "/" },  { title: "Меню", link: "/menu" }, { title: "Контакты", link: "/contacts" },];

export const Header = () => {
  const navigate = useNavigate();

  const handleChangePage = (page: string) => () => {
    navigate(page);
  }

    return (
        <div className="header">
            <img src="/images/logo.svg" alt="logo" className="header__logo" height={75} onClick={handleChangePage('/')} />

            <div className="header__section">
              {LINKS.map((item) => <div className={cn("header__link", {"header__link--selected": item.link === window.location.pathname})} onClick={handleChangePage(item.link)} key={item.title}>{item.title}</div>)}
            </div>
        </div>
    );
};
